import information from "@data/information.json";
import { Information, InformationJson } from "scripts/generateInformationJson";

const typedInformation = information as InformationJson;

interface ProductInformation extends Partial<Omit<Information, "skus">> {
  skus?: string;
}

export default (productInformation: ProductInformation) => {
  const productInformationKeys = Object.keys(productInformation) as (keyof Information)[];

  if (productInformation.skus) {
    return Object.values(typedInformation).filter((informationEl) =>
      informationEl.skus.includes(productInformation.skus as string)
    );
  }

  return Object.values(typedInformation).filter((informationEl) => {
    return productInformationKeys.every((key) => informationEl[key] === productInformation[key]);
  });
};

import "./src/styles/main.scss";
import "react-tooltip/dist/react-tooltip.css";
import "./sentry.config";

import posthog from "posthog-js";
import wrapWithProvider from "./wrap-with-provider";

export const onInitialClientRender = () => {
  posthog.init(process.env.GATSBY_POSTHOG_API_KEY, {
    api_host: process.env.GATSBY_POSTHOG_API_HOST,
    autocapture: true,
    capture_pageview: false,
  });

  trackPageView();
};

const trackPageView = () => {
  posthog.capture("$pageview", {
    url: window.location.pathname,
    referrer: document.referrer,
  });
};

export const onRouteUpdate = () => {
  trackPageView();
};

export const wrapRootElement = wrapWithProvider;

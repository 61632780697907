import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { CheckoutFormType } from "@pages/checkout";

import InputField from "@components/common/InputField";
import RadioButtonGroup from "@components/common/RadioButtonGroupField";
import PersonalInfoSummary from "@components/checkout/PersonalInfoSummary";

import { Option } from "@components/common/RadioButton";
import Icon from "@components/common/Icon";
import { isValidCardNumber } from "@helpers/checkout/isValidCardNumber";
import CheckboxField from "@components/common/CheckboxField";
import { useFormikContext } from "formik";
import PlacesAutocomplete from "@components/common/PlacesAutocomplete";
import SelectField from "@components/common/SelectField";
import stateOptions from "@helpers/data/stateOptions";

import { useSelector } from "react-redux";
import { selectCartItems, selectTotalPrice } from "@store/cart";
import { stepPrice } from "@components/widgets/cart/Cart";
import { CheckoutFormValues, PaymentsErrorsValues } from "./CheckoutFormContainer";
import ExpiredPopup from "./ExpiredPopup";

export type ShippingPrice = {
  price?: {
    label: string;
    value: number;
  };
};

export type ShippingType = "FREE" | "PRIORITY_SHIPPING";

export type ShippingTypesOptions = Option & ShippingPrice;

export const shippingTypesOptions: ShippingTypesOptions[] = [
  {
    label: "Free shipping (1-2 Business Days)",
    value: "FREE",
    price: {
      label: "",
      value: 0,
    },
    helper: (
      <React.Fragment>
        <span>Courier company: </span>
        <Icon name="usps" />
      </React.Fragment>
    ),
  },
  {
    label: "Shipping $4.99 (1-2 Business Days)",
    value: "PRIORITY_SHIPPING",
    price: {
      label: "",
      value: 4.99,
    },
  },
];

interface Props {
  formType: CheckoutFormType;
  shipping: ShippingType;
  setFormType: (newFormType: CheckoutFormType) => void;
  setFocus: Dispatch<SetStateAction<string | undefined>>;
  handleChangeShipping: (value: any) => void;
  validateExpiryDate: (month: string, year: string) => void;
  paymentsErrors: PaymentsErrorsValues;
  setError: (field: keyof PaymentsErrorsValues, message: string | null) => void;
  isShowPopupValid: boolean;
  isShowErrors: boolean;
  isPopupErrorCardVisible: boolean;
  isVisibleExpiredPopup: boolean;
}

const InvalidPopup = ({
  message,
  isInvalidNumber,
}: {
  message: string;
  isInvalidNumber: boolean;
}) => {
  return (
    <div className={`invalid-popup${isInvalidNumber ? " active" : ""}`}>
      <p style={{ color: "red" }}>{message}</p>
      <div className="invalid-popup-triangle" />
    </div>
  );
};

const CheckoutPaymentForm: FC<Props> = ({
  shipping,
  handleChangeShipping,
  validateExpiryDate,
  paymentsErrors,
  setError,
  isShowPopupValid,
  isShowErrors,
  isPopupErrorCardVisible,
  isVisibleExpiredPopup,
}) => {
  // if (formType !== "payment") return null;

  const cartItems = useSelector(selectCartItems);
  const totalPrice = useSelector(selectTotalPrice);

  const hasSubscriptionItems = cartItems.some((item) => item.product.subscriptionType !== "NONE");

  const shippingType =
    hasSubscriptionItems || totalPrice >= stepPrice[0] ? "FREE" : "PRIORITY_SHIPPING";

  const { values, setFieldValue, setValues, errors } = useFormikContext<CheckoutFormValues>();

  const isAutoCompleteDisable = process.env.GATSBY_GOOGLE_AUTOCOMPLETE_DISABLE === "true";

  const handleChange = (data: any, type?: string): void => {
    if (typeof data === "string") {
      return setFieldValue(type === "shipping" ? "streetAddress" : "diffStreetAddress", data);
    }

    const label = data?.structured_formatting?.main_text;

    setFieldValue(type === "shipping" ? "streetAddress" : "diffStreetAddress", label);

    void geocodeByPlaceId(data.place_id).then((res) => {
      const addressComponents = res?.[0]?.address_components || [];

      const postalCode = addressComponents.find((c) => c.types.includes("postal_code"))?.short_name;
      const state = addressComponents.find((c) =>
        c.types.includes("administrative_area_level_1")
      )?.short_name;
      const city = addressComponents.find(
        (c) => c.types.includes("locality") && c.types.includes("political")
      )?.long_name;

      setValues({
        ...values,
        [type === "shipping" ? "streetAddress" : "diffStreetAddress"]: label,
        [type === "shipping" ? "zipCode" : "diffZipCode"]: postalCode || "",
        [type === "shipping" ? "state" : "diffState"]: state || "",
        [type === "shipping" ? "city" : "diffCity"]: city || "",
      });
    });
  };

  useEffect(() => {
    handleChangeShipping(shippingType);
  }, [shippingType]);

  const popupText = isShowPopupValid
    ? "We accept cards which number starts with 3, 4, 5 or 6. Please, try again or use another card"
    : "Please enter a valid card number.";

  return (
    <div className="form-content payment-form">
      {/* <PersonalInfoSummary setFormType={setFormType} setFocus={setFocus} /> */}

      <h2 className="checkout-title">Shipping Method</h2>

      <div className="card shipping-form">
        <ul className="list-group">
          <RadioButtonGroup
            value={shippingType}
            onChange={handleChangeShipping}
            name="shippingType"
            options={shippingTypesOptions.filter((ship) => ship.value === shippingType)}
            disabledAllOptions
          />
        </ul>
      </div>

      <h2 className="checkout-title">Payment</h2>
      <div className="description-muted">
        <span>
          All transactions are secure and encrypted.
          <br />
          Billing will appear as "officialgroove" on your card statement.
        </span>
      </div>

      <div className="card">
        <div className="card-header">
          <Icon className="icon" name="shield-check-mark" />
          <p className="title">Secure Card Payment</p>
          <img
            src="/images/payment-methods.png"
            alt="payment methods"
            className="payment-methods"
            width={304}
            height={30}
          />
        </div>

        {(isShowPopupValid || isPopupErrorCardVisible) && (
          <InvalidPopup
            message={popupText}
            isInvalidNumber={isPopupErrorCardVisible && !isShowPopupValid}
          />
        )}
        <div className="card-body">
          <div className="row row-with-error">
            <div>
              <InputField
                name="cardNumber"
                autoComplete="cc-number"
                label="Card number"
                mask="9999 9999 9999 9999"
                maskChar=""
                iconName="lock"
                iconClassName="card-number-icon"
                className={
                  paymentsErrors.cardError ||
                  isShowPopupValid ||
                  (errors.cardNumber && isShowErrors)
                    ? "error-input"
                    : ""
                }
                labelOptions={{
                  className: paymentsErrors.cardError ? "error-message" : "",
                }}
                onBlur={(e) => {
                  const isValid = isValidCardNumber(e.target.value);

                  setError(
                    "cardError",
                    isValid
                      ? null
                      : "Invalid Card: Please check the card and type again or try another card. "
                  );
                }}
                tooltip={
                  <p>
                    All transactions are <br /> secure and encrypted.
                  </p>
                }
              />
              {paymentsErrors.cardError && (
                <span className="error-message">{paymentsErrors.cardError}</span>
              )}
              {errors.cardNumber && isShowErrors && !paymentsErrors.cardError && (
                <span className="error-message">{errors.cardNumber}</span>
              )}
            </div>
          </div>
          <div className="row">
            <InputField name="cardName" autoComplete="cc-name" label="Name on card" />
          </div>

          <div className="row">
            {isVisibleExpiredPopup && <ExpiredPopup />}
            <div className="row-with-error">
              <InputField
                name="cardExpiry"
                label="Expiration date (MM / YY)"
                mask="99/9999"
                maskChar=""
                autoComplete="cc-exp"
                className={
                  paymentsErrors.expiryError || (errors.cardExpiry && isShowErrors)
                    ? "error-input"
                    : ""
                }
                onBlur={(e) => {
                  const [month, year] = e.target.value.split("/");

                  validateExpiryDate(month, year);
                }}
                labelOptions={{
                  className: paymentsErrors.expiryError ? "error-message" : "",
                }}
              />
              {paymentsErrors.expiryError && (
                <span className="error-message">{paymentsErrors.expiryError}</span>
              )}
              {errors.cardExpiry && isShowErrors && !paymentsErrors.expiryError && (
                <span className="error-message">{errors.cardExpiry}</span>
              )}
            </div>

            <div className="row-with-error">
              <InputField
                name="cardCode"
                label="Security code"
                mask="9999"
                autoComplete="cc-csc"
                maskChar=""
                iconName="question-mark"
                className={
                  (paymentsErrors.cvvError && values.cardCode.length > 2) ||
                  (errors.cardCode && isShowErrors)
                    ? "error-input"
                    : ""
                }
                labelOptions={{
                  className:
                    paymentsErrors.cvvError && values.cardCode.length > 2 ? "error-message" : "",
                }}
                tooltip={
                  <p>
                    3-digit security code <br /> usually found on the back <br /> of your card.
                    American <br /> Express cards have a 4-
                    <br />
                    digit code located on the <br />
                    front.
                  </p>
                }
              />
              {paymentsErrors.cvvError && values.cardCode.length > 2 && (
                <span className="error-message">{paymentsErrors.cvvError}</span>
              )}
              {errors.cardCode && isShowErrors && paymentsErrors.cvvError === null && (
                <span className="error-message">{errors.cardCode}</span>
              )}
            </div>
          </div>
          <div className="row">
            <CheckboxField
              name="isSameAddress"
              label="Use shipping address as billing address"
              className="form-checkbox"
            />
          </div>

          {!values.isSameAddress && (
            <div className="different-address-fields">
              <div className="row">
                {isAutoCompleteDisable ? (
                  <InputField label="Street Address" name="diffStreetAddress" />
                ) : (
                  <PlacesAutocomplete
                    value={values.diffStreetAddress}
                    onChange={(data) => handleChange(data, "billing")}
                    label="Street Address"
                  />
                )}

                <SelectField
                  placeholder="State"
                  name="diffState"
                  options={stateOptions}
                  animatedPlaceholder
                />
              </div>

              <div className="row">
                <InputField
                  label="Apartment, suite, building (optional)"
                  name="diffStreetAddress2"
                />
              </div>

              <div className="row">
                <InputField label="Town / City" name="diffCity" />
                <InputField label="Country" name="diffCountry" disabled />
                <InputField label="Zip Code" name="diffZipCode" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaymentForm;

import React, { useCallback } from "react";

import classNames from "classnames";

import { CartItem as ICartItem } from "@typings/cart";

import { useDispatch } from "react-redux";
import { closeCart } from "@store/cart";
import { navigate } from "gatsby";

import { SubscriptionType } from "scripts/generateProductsJson";
import getInformation from "@helpers/information/getInformation";

import CartItemProperty from "./property/CartItemProperty";
import CartItemFooter from "./footer/CartItemFooter";
import CartItemImg from "./img/CartItemImg";
import CartItemHeader from "./title/CartItemHeader";

export const SUBSCRIPTION_TYPE_MAP: Record<SubscriptionType, string> = {
  NONE: "One Time",
  MONTHLY: "Refill Every 30 Days",
  EVERY_2_MONTHS: "Every 2 months",
  EVERY_3_MONTHS: "Every 3 months",
  EVERY_6_MONTHS: "Every 6 months",
  EVERY_12_MONTHS: "Every 12 months",
};

export type CartItemType = "EDIT" | "ADD";

interface CartItemProps {
  cartItem: ICartItem;

  className?: string;

  type?: CartItemType;

  hideRemoveBtn?: boolean;
  hideCounter?: boolean;
  isRemoveProgressProduct?: boolean;
}

const CartItem = ({
  cartItem,
  className,
  type = "EDIT",
  hideRemoveBtn,
  hideCounter,
  isRemoveProgressProduct,
}: CartItemProps) => {
  const { product } = cartItem;

  const information = getInformation({ skus: product.sku });

  const dispatch = useDispatch();

  const redirectToProductPage = useCallback(() => {
    if (information.slug) {
      void navigate(`/shop/${information.slug}`);
    }

    dispatch(closeCart());
  }, [dispatch, information?.slug]);

  return (
    <React.Fragment>
      <div className={classNames("cart-item", className)}>
        <CartItemImg information={information} redirectToProductPage={redirectToProductPage} />

        <div className="cart-item__info">
          <CartItemHeader
            information={information}
            cartItem={cartItem}
            redirectToProductPage={redirectToProductPage}
          />

          {product.subscriptionType && (
            <CartItemProperty name={SUBSCRIPTION_TYPE_MAP[product.subscriptionType]} />
          )}

          <CartItemFooter
            cartItem={cartItem}
            type={type}
            hideRemoveBtn={hideRemoveBtn}
            hideCounter={hideCounter}
            isRemoveProgressProduct={isRemoveProgressProduct}
          />
        </div>
      </div>

      <hr className="cart-item__separator" />
    </React.Fragment>
  );
};

export default CartItem;
